import React from 'react'
import { DefaultLayout } from '../components/Layout'
import { Ellipsis } from '../components/Typography'

const NewsPage = () => (
  <DefaultLayout>
    <h1>
      News
      <Ellipsis />
    </h1>
  </DefaultLayout>
)

export default NewsPage
